<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Kategori </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateKategori">
            <CRow>
              <CCol sm="12">
              <CInput
                v-model="nama_kategori"
                label="Nama Kategori"
                placeholder="Input Nama Kategori"
              />
              </CCol>
              <CCol sm="4">
                <div class="form-group">
                  <label>Jenis Data</label>
                    <select v-model="jenis_data" placeholder="-- Pilih Jenis Data --" class="form-control">
                      <option value="" disabled selected>-- Pilih Jenis Data --</option>
                      <option value="spasial">Spasial</option>
                      <option value="dokumen">Dokumen</option>
                    </select>
                </div>
              </CCol> 
              <CCol sm="4">             
                <div class="form-group">
                <label>Parent</label>
                  <select class="form-control" v-model="id_parent">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="0">N/A</option>
                    <option v-for="r in kategoris" :value="r.id_kategori">{{r.nama_kategori}} | {{ r.jenis_data }} </option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="4">             
                <div class="form-group">
                <label>Level</label>
                  <select class="form-control" v-model="level">
                    <option value="1">Level 1</option>
                    <option value="2">Level 2</option>
                    <option value="3">Level 3</option>
                    <option value="4">Level 4</option>
                    <option value="5">Level 5</option>
                    <option value="6">Level 6</option>
                    <option value="7">Level 7</option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="4">
                <div class="form-group">
                  <label>Status Aktif</label>
                  <select v-model="status_aktif_kategori" placeholder="-- Pilih Status --" class="form-control">
                      <option value="" disabled selected>-- Pilih Status --</option>
                      <option value="1">Aktif</option>
                      <option value="0">Tidak Aktif</option>
                  </select>
                </div>
              </CCol>
              <!-- <CCol sm="6">
                <div class="form-group">
                  <label>Foto Kategori</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                    <input type="hidden" v-model="foto1">
                  </div>
                </div>  
              </CCol> -->
            </CRow>       
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/kategori">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nama_kategori : "",
      jenis_data : "",
      level : "",
      id_parent : "",
      status_aktif_kategori : "",
      placeholder : "Pilih File",
      selected: [], // Must be an array reference!
      kategoris: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showKategori();
  },

  methods: {
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"kategoris")
        .then((response) => {
              this.kategoris=response.data;
        });
    },

    updateKategori: function(event){

      const kategori = { 
                          nama_kategori: this.nama_kategori, 
                          jenis_data: this.jenis_data,
                          id_parent: this.id_parent,
                          status_aktif_kategori: this.status_aktif_kategori,
                          level: this.level
                          };

      // alert(JSON.stringify(pekerjaan));
      axios.put(process.env.VUE_APP_BASE_URL+"kategori/"+this.$route.params.id_kategori, kategori)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/kategori');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showKategori: function(event){
      // Simple POST request with a JSON body using axios
      const kategori = { 
                          nama_kategori: this.nama_kategori, 
                          jenis_data: this.jenis_data,
                          id_parent: this.id_parent,
                          status_aktif_kategori: this.status_aktif_kategori
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"kategori/"+this.$route.params.id_kategori)
        .then((response) => {            
              this.nama_kategori=response.data.nama_kategori;
              this.jenis_data=response.data.jenis_data;
              this.status_aktif_kategori=response.data.status_aktif_kategori;
              this.id_parent=response.data.id_parent;
              this.level=response.data.level;
        })
    }
  }
}
</script>